<template>
  <div>
    <p>
      Observatory V collapses the distance between the skull you are<FancyBreak />
      looking out from and the boundless space you are seeing from.
    </p>
    <p>
      This Observatory allows you to see that here<FancyBreak />
      at zero distance from your self, you are endless.
    </p>
    <p>
      When all distance collapses, you are no longer looking<FancyBreak />
      into the world—all of infinity is looking out of you.
    </p>
    <p>
      Only what is infinite is real, and what is infinite is here.<FancyBreak />
      You are here.
    </p>
  </div>
</template>

<script>
import FancyBreak from "@/components/FancyBreak";

export default {
  components: {
    FancyBreak,
  },
};
</script>
