<template>
  <div>
    <p>
      Observatory II dissolves the distance between<FancyBreak />
      where you are seeing from and where I am seeing from.
    </p>
    <p>
      This Observatory allows you to look out from the same<FancyBreak />
      zero distance I am looking out from.
    </p>
    <p>
      When all distance between us dissolves, it is no longer you<FancyBreak />
      that is looking—all of empty space is looking out of you.
    </p>
    <p>
      Only emptiness is seeing, and emptiness has no center.<FancyBreak />
      You are this centerless seeing.
    </p>
  </div>
</template>

<script>
import FancyBreak from "@/components/FancyBreak";

export default {
  components: {
    FancyBreak,
  },
};
</script>
