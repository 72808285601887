<template>
  <div>
    <p>
      Observatory I turns around the arrow of attention<FancyBreak />
      from what you are looking at to where you are seeing from.
    </p>
    <p>
      This Observatory allows you to see that at zero distance from yourself,
      you are<FancyBreak />
      utterly empty.
    </p>
    <p>
      When all distance collapses, you are no longer appearing in your
      experience—and<FancyBreak />
      yet, you are here.
    </p>
    <p>
      Only seeing is present, and seeing is empty.<FancyBreak />
      You are this empty seeing.
    </p>
  </div>
</template>

<script>
import FancyBreak from "@/components/FancyBreak";

export default {
  components: {
    FancyBreak,
  },
};
</script>
