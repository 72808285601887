<template>
  <div>
    <p>
      Observatory IV turns around the arrow of looking from<FancyBreak />
      the reflection of your face to the absence you are seeing from.
    </p>
    <p>
      This Observatory allows you to see that at zero distance<FancyBreak />
      from yourself, you do not exist.
    </p>
    <p>
      When the reflection of your face collapses, you are no longer<FancyBreak />
      looking into the world—all of non existence is looking out of<FancyBreak />
      you.
    </p>
    <p>
      Only what is absent is present, and non existence is here.<FancyBreak />
      You are here.
    </p>
  </div>
</template>

<script>
import FancyBreak from "@/components/FancyBreak";

export default {
  components: {
    FancyBreak,
  },
};
</script>
