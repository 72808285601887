<template>
  <div>
    <p>
      Observatory III collapses the distance between the empty space<FancyBreak />
      you are seeing from and the world you are looking at.
    </p>
    <p>
      This Observatory allows you to see that here<FancyBreak />
      at zero distance from your self, you are this world.
    </p>
    <p>
      When all distance collapses, you are no longer looking<FancyBreak />
      into the world—all of the world is looking out of you.
    </p>
    <p>
      Only emptiness is real, and emptiness is this world.<FancyBreak />
      You are this empty fullness.
    </p>
  </div>
</template>

<script>
import FancyBreak from "@/components/FancyBreak";

export default {
  components: {
    FancyBreak,
  },
};
</script>
